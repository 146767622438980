import React from 'react'
import { Link } from 'react-router-dom'
import Ad from '../../components/Ad'
import FORMATS from '../../components/AdFormats'
import { Mobile, MobileElse } from 'ion-media'
import { View } from 'ion-icon'
import { ImageOrNoImage } from 'ion-image'
import useHasMounted from '../../components/useHasMounted'

function kFormatter (num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

const ArticleCard = ({ article, index, isConnected, withReadCount, noImage }) => {
  const hasMounted = useHasMounted()
  return (
    <>
      <article>
        <Link to={'/' + article.getCanonicalUri()}>
          <Mobile>
            <ImageOrNoImage image={article.image} width={320} alt={article.headline} shape='square' noImage={noImage} />
          </Mobile>
          {hasMounted &&
            <MobileElse>
              {index === 0 && (
                <ImageOrNoImage image={article.image} width={680} alt={article.headline} shape='Cinema2K' noImage={noImage} />
              )}
              {index !== 0 && (
                <ImageOrNoImage image={article.image} width={518} alt={article.headline} shape='square' noImage={noImage} />
              )}
            </MobileElse>}
          <div className='content'>
            <h3>{article.headline}</h3>
            {index === 0 && (
              <small className='meta'>
                <span className='date'>{article.formatAgo()}</span> | {article.sectionLabel}
              </small>
            )}
            {(article.sponsoredIndicator === 'SPONSORED' || article.sponsoredIndicator === 'SPONSORED_EXPIRED') &&
              <small className='sponsored'>Sponsored</small>}
            {(article.sponsoredIndicator === 'NATIVE' || article.sponsoredIndicator === 'NATIVE_EXPIRED') &&
              <small className='native'>Partnered</small>}
          </div>
          {/* NOTE: React bug
            When this div is placed before the previous one, and the server does not generate the below div, when the
            client does, react will resuse the previous div node and not set the className. Ways to fix this would be to
            move the node, or possibly not making a div but a span
          */}
          {withReadCount && article.readCount > 200
            ? (
              <div className='views'><View width='12' height='12' />{kFormatter(article.readCount)}</div>
              )
            : ''}
        </Link>
      </article>
      {index === 2 && (
        <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='mpu-topright' targeting={{ mpu: 'topright' }} collapseEmptyDiv className='ad-mpudynamic-topright' {...FORMATS.mpu600} />
      )}
    </>
  )
}

const Default = ({ articles, section, noImage, isConnected, withReadCount }) => {
  return articles.map((article, index) => {
    return (
      <ArticleCard key={article.contentKey} article={article} index={index} isConnected={isConnected} noImage={noImage} withReadCount={withReadCount} />
    )
  })
}

export default Default
